import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { request } from 'octiv-utilities/Request';

export const useSettingsFindAccountsTenant = (input, options) => {
  return useQuery(
    ['settings', 'findAccountsTenant', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/accounts/tenant`,
      }),
    options
  );
};

export const useSettingsFindTenantFinances = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  return useQuery(
    ['settings', 'findAccountsTenant', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${tenantId}/finances`,
      }),
    options
  );
};

export const useSettingsUpdateTenantFinances = (options) => {
  const { tenantId } = useActiveUserTenant();
  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};
      let { cashMemberInvoiceGenerationDay } = data;

      if (cashMemberInvoiceGenerationDay === '0') {
        cashMemberInvoiceGenerationDay = null;
      }

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/finances`,
        data: {
          memberBillingCurrency: data.memberBillingCurrency,
          deactivateMemberOnContractEnd: data.deactivateMemberOnContractEnd,
          cashMemberInvoiceGenerationDay, // : data.cashMemberInvoiceGenerationDay,
          cashMemberInvoiceDueDay: data.cashMemberInvoiceDueDay,
          cashMemberInvoiceStrategy: data.cashMemberInvoiceStrategy,
        },
      });
    },
  });
};

export const useSettingsUpdateAccountsTenant = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/accounts/tenant`,
        data: {
          memberBillingCurrency: data.memberBillingCurrency,
          deactivateMemberOnContractEnd: data.deactivateMemberOnContractEnd,
          cashMemberInvoiceGenerationDay: data.cashMemberInvoiceGenerationDay,
          cashMemberInvoiceDueDay: data.cashMemberInvoiceDueDay,
          cashMemberInvoiceStrategy: data.cashMemberInvoiceStrategy,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindAccountsInvoiceDetails = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['settings', 'findAccountsInvoiceDetails', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/locations/${filter.locationId}/finances/invoices`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useSettingsUpdateAccountsInvoiceDetails = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'post',
        url: `/api/settings/locations/${data.locationId}/finances/invoices`,
        // TODO: clean
        data: {
          locationId: data.locationId,
          vatNumber: data.vatNumber,
          vatPercent: Number(data.vatPercent),
          invoiceInformation: data.invoiceInformation,
          showInvoiceTotals: data.showInvoiceTotals,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsUpdateAccountsInvoiceDetailsImage = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      const formData = new FormData();

      formData.append('logo', data.logo);

      return request({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'post',
        url: `/api/settings/locations/${data.locationId}/finances/invoices`,
        data: formData,
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindAccountsDebitOrder = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  return useQuery(
    ['settings', 'findAccountsDebitOrder', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${tenantId}/finances/debit-orders`,
      }),
    options
  );
};

export const useSettingsUpdateAccountsDebitOrder = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/finances/debit-orders`,
        data: { debitOrderSettings: data.debitOrderSettings },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindBookings = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['settings', 'findBookings', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${tenantId}/bookings`,
      }),
    options
  );
};

export const useSettingsUpdateBookings = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/bookings`,
        data: {
          bookingThreshold: data.bookingThreshold,
          isLimitInterFacilityBookings: data.isLimitInterLocationBookings,
          locationSettings: data.locationSettings,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindAccessControl = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['settings', 'findAccessControl', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/access-control`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useSettingsUpdateAccessControl = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/access-control`,
        data: {
          locationId: data.locationId,
          accessControlUsername: data.accessControlUsername,
          password: data.password,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindContract = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['settings', 'findContract', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${tenantId}/contracts`,
      }),
    options
  );
};

export const useSettingsUpdateContract = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/contracts`,
        data: {
          contractExpiryNotificationDays: data.contractExpiryNotificationDays,
          contractTermsAndConditions: data.contractTermsAndConditions,
          isUserContractVisibleInApp: data.isUserContractVisibleInApp,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindWaiver = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input;

  return useQuery(
    ['settings', 'findWaiver', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${filter?.tenantId || tenantId}/waivers`,
      }),
    options
  );
};

export const useSettingsUpdateWaiver = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const { id, ...data } = input || {};

      const isDigital = data?.isDigital;

      return request({
        method: 'post',
        // NOTE: Allows for the file upload, as well as updating the raw text (with rich text editor)
        headers: {
          'Content-Type': isDigital
            ? 'application/json'
            : 'multipart/form-data',
        },
        url: `/api/settings/tenants/${tenantId}/waivers/${id}`,
        data: {
          isDigital: Number(data.isDigital),
          digitalTermsAndConditions: isDigital
            ? data.digitalTermsAndConditions
            : undefined,
          file: isDigital ? undefined : data.file,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindWorkout = (input, options) => {
  const { tenantId } = useActiveUserTenant();

  return useQuery(
    ['settings', 'findWorkout', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${tenantId}/workouts`,
      }),
    options
  );
};

export const useSettingsUpdateWorkout = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/workouts`,
        data: { workoutThreshold: data.workoutThreshold },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindStores = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['settings', 'findStores', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/locations/${filter.locationId}/point-of-sale`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useSettingsUpdateStores = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/locations/${data.locationId}/point-of-sale`,
        data: {
          locationId: data.locationId,
          paymentOptions: data.paymentOptions,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindWidget = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['settings', 'findWidget', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/widget`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useSettingsUpdateWidget = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/widget`,
        data: {
          locationId: data.locationId,
          signUpRedirectUrl: data.signUpRedirectUrl,
          leadRedirectUrl: data.leadRedirectUrl,
          leadRequestDemoRedirectUrl: data.leadRequestDemoRedirectUrl,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

// NOTE: Think we don't use the `api/settings/widget` endpoints above anymore, so should prob delete

export const useSettingsFindWidgets = (input) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input || {};

  return useQuery(['settings', 'findWidgets'], () =>
    request({
      method: 'get',
      url: `/api/settings/tenants/${tenantId}/widgets`,
      params: {
        locationId: filter.locationId,
      },
    })
  );
};

export const useSettingsUpdateWidgets = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/widgets`,
        data: {
          signUpRedirectUrl: data.signUpRedirectUrl,
          leadRedirectUrl: data.leadRedirectUrl,
          leadRequestDemoRedirectUrl: data.leadRequestDemoRedirectUrl,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });
      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindPos = (input, options) => {
  const { filter = {} } = input || {};

  return useQuery(
    ['settings', 'findPos', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/pos`,
        params: { filter: { locationId: filter.locationId } },
      }),
    options
  );
};

export const useSettingsUpdatePos = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/pos`,
        data: {
          locationId: data.locationId,
          paymentOptions: data.paymentOptions,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};

export const useSettingsFindSignUp = (input, options) => {
  const { tenantId } = useActiveUserTenant();
  const { filter = {} } = input;

  return useQuery(
    ['settings', 'findSignUp', input],
    () =>
      request({
        method: 'get',
        url: `/api/settings/tenants/${
          filter.tenantId || tenantId
        }/registrations`,
      }),
    options
  );
};

export const useSettingsUpdateSignUp = (options) => {
  const queryClient = useQueryClient();
  const { tenantId } = useActiveUserTenant();

  return useMutation({
    ...options,
    mutationFn: (input) => {
      const data = input || {};

      return request({
        method: 'put',
        url: `/api/settings/tenants/${tenantId}/registrations`,
        data: {
          signUpPaymentOptions: data.signUpPaymentOptions,
          signUpDebitDayOptions: data.signUpDebitDayOptions,
          signUpRequiredFields: data.signUpRequiredFields,
          isSignUpUseContractsAndWaivers: data.isSignUpUseContractsAndWaivers,
          proRateStrategy: data.proRateStrategy,
        },
      });
    },
    onSuccess: (...res) => {
      queryClient.invalidateQueries({ queryKey: ['settings'] });

      options?.onSuccess?.(...res);
    },
  });
};
