import { Formik } from 'formik';
import { Box, Button, Col, Field, Row, Text } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { daysOfMonthStringOptions } from 'octiv-utilities/Constants';
import {
  getDateReadableDayMonthYear,
  getLabelsAndValuesFromNumber,
} from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { paymentTypes } from '.';

export const sanitizeValues = ({
  memberDetails = {},
  packageId,
  contractDetails: { startDate, endDate, document } = {},
  discountDetails: { discountId, amount } = {},
  paymentDetails: {
    // All
    debitStatusId,

    // Cash, EFT, Card
    invoicingType,
    autoInvoicingDay,
    autoInvoicingDueDay,

    // Debit Order
    bankId,
    accountTypeId,
    accountNumber,
    accountHolderName,
    branchCode,
    iban,
    bic,
    address,
    debitDayId,
    proRatedAmount,
    file,

    // Upfront
    upfrontPaymentMethod,
    upfrontPaymentAmount,
    upfrontPaymentStartDate,
    upfrontPaymentPeriodType,
    upfrontPaymentPeriod,
  } = {},
}) => {
  const data = {
    typeId: 4,
    packageId,
    ...memberDetails,
    ...(startDate
      ? {
          contractDetails: {
            startDate,
            endDate,
            document,
          },
        }
      : {}),
    ...(discountId
      ? {
          discountDetails: {
            discountType:
              discountId === 'specialRate' ? 'specialRate' : 'discount',
            ...(discountId === 'specialRate' ? { amount } : { discountId }),
          },
        }
      : {}),
  };

  switch (debitStatusId) {
    case paymentTypes.CASH:
      data.paymentDetails = {
        debitStatusId,
        invoicingType,
        autoInvoicingDay,
        autoInvoicingDueDay,
      };
      break;

    case paymentTypes.DEBIT_ORDER:
      data.paymentDetails = {
        debitStatusId,
        bankId,
        accountTypeId,
        accountNumber,
        accountHolderName,
        branchCode,
        iban,
        bic,
        address,
        debitDayId,
        proRatedAmount,
        file,
      };
      break;

    case paymentTypes.UPFRONT:
      data.paymentDetails = {
        debitStatusId,
        upfrontPaymentMethod,
        upfrontPaymentAmount,
        upfrontPaymentStartDate,
        upfrontPaymentPeriodType,
        upfrontPaymentPeriod,
      };
      break;

    default:
      data.paymentDetails = {
        debitStatusId,
      };
      break;
  }

  return data;
};

export const getInitialValues = ({ discountDetails, paymentDetails } = {}) => ({
  discountDetails: {
    discountId:
      discountDetails?.type === 'specialRate'
        ? 'specialRate'
        : discountDetails?.discount?.id || undefined,
    amount: discountDetails?.amount || undefined,
  },
  paymentDetails: {
    // All
    debitStatusId: paymentDetails?.userDebitStatus?.id || undefined,

    // Cash, EFT, Card
    invoicingType: paymentDetails?.autoInvoicingDay
      ? 'customDates'
      : 'tenantInvoicing',
    autoInvoicingDay: paymentDetails?.autoInvoicingDay || undefined,
    autoInvoicingDueDay: paymentDetails?.autoInvoicingDueDay || undefined,

    // Debit Order
    bankId: paymentDetails?.bank?.id || undefined,
    accountTypeId: paymentDetails?.accountType?.id || undefined,
    accountNumber: paymentDetails?.accountNumber || undefined,
    accountHolderName: paymentDetails?.accountHolderName || undefined,
    branchCode: paymentDetails?.branchCode || undefined,
    iban: paymentDetails?.iban || undefined,
    bic: paymentDetails?.bic || undefined,
    address: paymentDetails?.address || undefined,
    debitDayId: paymentDetails?.debitDay?.id || undefined,
    file: paymentDetails?.file || undefined,
    proRatedAmount: paymentDetails?.proRatedAmount || undefined,

    // Upfront
    upfrontPaymentMethod: undefined,
    upfrontPaymentAmount: undefined,
    upfrontPaymentStartDate: undefined,
    upfrontPaymentPeriodType: undefined,
    upfrontPaymentPeriod: undefined,
  },
});

export const getValidationSchema = ({ t, isGoCardless, isSepa }) => ({
  discountDetails: Yup.object().shape({
    discountId: Yup.string(),
    amount: Yup.string().when('discountId', {
      is: (value) => value === 'specialRate',
      then: Yup.string().required(t('required')),
    }),
  }),
  paymentDetails: Yup.object().shape({
    // All
    debitStatusId: Yup.string().required(t('required')),

    // Cash, EFT, Card
    invoicingType: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.CASH,
      then: Yup.string().required(t('required')),
    }),
    autoInvoicingDay: Yup.string().when(['debitStatusId', 'invoicingType'], {
      is: (debitStatusId, invoicingType) =>
        Number(debitStatusId) === paymentTypes.CASH &&
        invoicingType === 'customDates',
      then: Yup.string().required(t('required')),
    }),
    autoInvoicingDueDay: Yup.string().when(['debitStatusId', 'invoicingType'], {
      is: (debitStatusId, invoicingType) =>
        Number(debitStatusId) === paymentTypes.CASH &&
        invoicingType === 'customDates',
      then: Yup.string().required(t('required')),
    }),

    // Debit Order
    ...(!isGoCardless &&
      !isSepa && {
        bankId: Yup.string().when('debitStatusId', {
          is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
          then: Yup.string().required(t('required')),
        }),
        accountTypeId: Yup.string().when('debitStatusId', {
          is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
          then: Yup.string().required(t('required')),
        }),
        accountNumber: Yup.string().when('debitStatusId', {
          is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
          then: Yup.string().required(t('required')),
        }),
      }),
    ...(!isGoCardless && {
      accountHolderName: Yup.string().when('debitStatusId', {
        is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
        then: Yup.string().required(t('required')),
      }),
    }),
    ...(isSepa && {
      iban: Yup.string().when('debitStatusId', {
        is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
        then: Yup.string().required(t('required')),
      }),
      bic: Yup.string().when('debitStatusId', {
        is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
        then: Yup.string().required(t('required')),
      }),
      address: Yup.string().when('debitStatusId', {
        is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
        then: Yup.string(),
      }),
    }),
    branchCode: Yup.string(),
    debitDayId: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.DEBIT_ORDER,
      then: Yup.string().required(t('required')),
    }),
    file: Yup.string(),
    proRatedAmount: Yup.string(),

    // Upfront
    upfrontPaymentMethod: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.UPFRONT,
      then: Yup.string().required(t('required')),
    }),
    upfrontPaymentAmount: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.UPFRONT,
      then: Yup.string().required(t('required')),
    }),
    upfrontPaymentStartDate: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.UPFRONT,
      then: Yup.string().required(t('required')),
    }),
    upfrontPaymentPeriodType: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.UPFRONT,
      then: Yup.string().required(t('required')),
    }),
    upfrontPaymentPeriod: Yup.string().when('debitStatusId', {
      is: (value) => Number(value) === paymentTypes.UPFRONT,
      then: Yup.string().required(t('required')),
    }),
  }),
});

export const renderFormFields = ({
  t,
  banks,
  discountId,
  discounts,
  hasProRatedAmount = false,
  invoicingType,
  isFetchingBanks,
  isFetchingDiscounts,
  isGoCardless,
  isSepa,
  isDisabledSepaFields,
  isVisibleUpfrontPaymentPeriod,
  setFieldTouched,
  setFieldValue,
  upfrontPaymentPeriodType,
  debitStatusId,
}) => (
  <>
    <Text mb={2} variant='heading'>
      {t('discount')}
    </Text>

    <Row>
      <Col lg={3} md={6}>
        <Field
          isSelect
          isLoading={isFetchingDiscounts}
          label={t('discount')}
          name='discountDetails.discountId'
          options={[
            {
              label: t('noDiscount'),
              value: undefined,
            },
            {
              label: t('specialRate'),
              value: 'specialRate',
            },
            ...discounts.map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ]}
        />
      </Col>

      {discountId === 'specialRate' && (
        <Col lg={3} md={6}>
          <Field label={t('specialRateAmount')} name='discountDetails.amount' />
        </Col>
      )}
    </Row>

    <Text mb={2} mt={4} variant='heading'>
      {t('paymentDetails')}
    </Text>

    <Row>
      <Col lg={3} md={6}>
        <Field
          isSelect
          label={t('paymentType')}
          name='paymentDetails.debitStatusId'
          options={[
            {
              label: t('cashEftCard'),
              value: paymentTypes.CASH,
            },
            {
              label: t('debitOrder'),
              value: paymentTypes.DEBIT_ORDER,
            },
            {
              label: t('noPayment'),
              value: paymentTypes.NONE,
            },
            {
              label: t('upfrontPayment'),
              value: paymentTypes.UPFRONT,
            },
            {
              label: t('onlinePayment'),
              value: paymentTypes.ONLINE,
            },
          ]}
        />
      </Col>

      {debitStatusId === paymentTypes.CASH && (
        <>
          <Col lg={3} md={6}>
            <Field
              isSelect
              label={t('invoicingType')}
              name='paymentDetails.invoicingType'
              options={[
                {
                  label: t('useTenantInvoicingDates'),
                  value: 'tenantInvoicing',
                },
                {
                  label: t('useCustomDates'),
                  value: 'customDates',
                },
              ]}
            />
          </Col>

          {invoicingType === 'customDates' && (
            <>
              <Col lg={3} md={6}>
                <Field
                  isSelect
                  label={t('invoiceDay')}
                  name='paymentDetails.autoInvoicingDay'
                  options={daysOfMonthStringOptions}
                />
              </Col>

              <Col lg={3} md={6}>
                <Field
                  isSelect
                  label={t('invoiceDueDay')}
                  name='paymentDetails.autoInvoicingDueDay'
                  options={daysOfMonthStringOptions}
                />
              </Col>
            </>
          )}
        </>
      )}

      {debitStatusId === paymentTypes.DEBIT_ORDER && (
        <>
          <Col lg={3} md={6}>
            <Field
              isSelect
              label={t('debitDate')}
              name='paymentDetails.debitDayId'
              options={[
                { label: '1st', value: 1 },
                { label: '5th', value: 6 },
                { label: '15th', value: 2 },
                { label: '25th', value: 3 },
                { label: '27th', value: 5 },
                { label: '28th', value: 7 },
                { label: t('lastDayMonth'), value: 4 },
              ]}
            />
          </Col>

          {!isGoCardless && !isSepa && (
            <>
              <Col lg={3} md={6}>
                <Field
                  isSelect
                  isLoading={isFetchingBanks}
                  label={t('bank')}
                  name='paymentDetails.bankId'
                  options={banks.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Col>

              <Col lg={3} md={6}>
                <Field
                  isSelect
                  label={t('accountType')}
                  name='paymentDetails.accountTypeId'
                  options={[
                    { label: t('accountTypeChequeCurrent'), value: 1 },
                    { label: t('accountTypeSavings'), value: 2 },
                    { label: t('accountTypeBond'), value: 4 },
                    { label: t('accountTypeSubscription'), value: 5 },
                    { label: t('accountTypeTransmission'), value: 3 },
                  ]}
                />
              </Col>

              <Col lg={3} md={6}>
                <Field
                  label={t('accountNumber')}
                  name='paymentDetails.accountNumber'
                />
              </Col>

              <Col lg={3} md={6}>
                <Field
                  label={t('accountName')}
                  name='paymentDetails.accountHolderName'
                />
              </Col>

              <Col lg={3} md={6}>
                <Field
                  label={t('accountBranchCode')}
                  name='paymentDetails.branchCode'
                />
              </Col>
            </>
          )}

          {isSepa && (
            <Col>
              <Row>
                <Col lg={4} md={6}>
                  <Field
                    isDisabled={isDisabledSepaFields}
                    label={t('accountHolder')}
                    name='paymentDetails.accountHolderName'
                  />
                </Col>

                <Col lg={4} md={6}>
                  <Field
                    isDisabled={isDisabledSepaFields}
                    label={t('internationalBankAccountNumber')}
                    name='paymentDetails.iban'
                  />
                </Col>

                <Col lg={4} md={6}>
                  <Field
                    isDisabled={isDisabledSepaFields}
                    label={t('bankIdentifierCode')}
                    name='paymentDetails.bic'
                  />
                </Col>

                <Col>
                  <Field
                    as='textarea'
                    isDisabled={isDisabledSepaFields}
                    label={t('address')}
                    name='paymentDetails.address'
                  />
                </Col>
              </Row>
            </Col>
          )}

          {hasProRatedAmount && (
            <Col lg={3} md={6}>
              <Field
                label={t('firstInvoiceAmountIncludingProRate')}
                name='paymentDetails.proRatedAmount'
              />
            </Col>
          )}

          <Col>
            <Field
              isDrop
              label={t('waiverOptional')}
              name='paymentDetails.file'
            />
          </Col>
        </>
      )}

      {!isVisibleUpfrontPaymentPeriod &&
        debitStatusId === paymentTypes.UPFRONT && (
          <>
            <Col lg={3} md={6}>
              <Field
                isSelect
                label={t('paymentMethod')}
                name='paymentDetails.upfrontPaymentMethod'
                options={[
                  { label: t('cash'), value: 'cash' },
                  { label: t('eft'), value: 'eft' },
                  { label: t('debitCreditCard'), value: 'card' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                label={t('paymentAmount')}
                name='paymentDetails.upfrontPaymentAmount'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isDate
                label={t('paymentStartDate')}
                name='paymentDetails.upfrontPaymentStartDate'
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isSelect
                doFinally={() => {
                  setFieldTouched('paymentDetails.upfrontPaymentPeriod', false);
                  setFieldValue(
                    'paymentDetails.upfrontPaymentPeriod',
                    undefined
                  );
                }}
                label={t('paymentPeriodType')}
                name='paymentDetails.upfrontPaymentPeriodType'
                options={[
                  { label: t('weeks'), value: 'weeks' },
                  { label: t('months'), value: 'months' },
                ]}
              />
            </Col>

            <Col lg={3} md={6}>
              <Field
                isSelect
                label={t('paymentPeriod')}
                name='paymentDetails.upfrontPaymentPeriod'
                options={getLabelsAndValuesFromNumber({
                  numberFrom: 1,
                  numberTo: upfrontPaymentPeriodType === 'weeks' ? 52 : 36,
                })}
              />
            </Col>
          </>
        )}
    </Row>
  </>
);

export default ({
  banks,
  discounts,
  initialValues = {},
  isFetchingBanks,
  isFetchingDiscounts,
  isLoading,
  onRenewUpfrontPayment,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    tenant: { isGoCardless, isSepa },
  } = useActiveUserTenant();
  const { discountDetails, paymentDetails } = initialValues;

  let upfrontPaymentPeriod;
  if (paymentDetails?.upfrontInvoiceStartDate) {
    const { upfrontInvoiceStartDate, upfrontInvoiceEndDate } = paymentDetails;

    upfrontPaymentPeriod = `${t(
      'currentUpfrontPaymentPeriod'
    )}: ${getDateReadableDayMonthYear({
      date: upfrontInvoiceStartDate,
    })} ${t('to').toLowerCase()} ${getDateReadableDayMonthYear({
      date: upfrontInvoiceEndDate,
    })}.`;
  }

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={getInitialValues({
        discountDetails,
        paymentDetails,
      })}
      validationSchema={Yup.object().shape(
        getValidationSchema({ t, isGoCardless, isSepa })
      )}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        dirty,
        values: {
          discountDetails: { discountId },
          paymentDetails: {
            debitStatusId,
            invoicingType,
            upfrontPaymentPeriodType,
          },
        },
      }) => {
        const isVisibleUpfrontPaymentPeriod =
          upfrontPaymentPeriod && debitStatusId === paymentTypes.UPFRONT;

        return (
          <form onSubmit={handleSubmit}>
            {renderFormFields({
              t,
              banks,
              discountId,
              discounts,
              invoicingType,
              isFetchingBanks,
              isFetchingDiscounts,
              isGoCardless,
              isSepa,
              // TODO: isDisabledSepaFields: !!paymentDetails?.iban,
              isDisabledSepaFields: false,
              isVisibleUpfrontPaymentPeriod,
              setFieldTouched,
              setFieldValue,
              upfrontPaymentPeriodType,
              debitStatusId,
            })}

            {isVisibleUpfrontPaymentPeriod && (
              <Text mt={2}>{upfrontPaymentPeriod}</Text>
            )}

            <Box isFlex mt={4}>
              {isVisibleUpfrontPaymentPeriod && (
                <Button
                  hasBorder
                  mr={2}
                  text={t('renewUpfrontPayment')}
                  onClick={onRenewUpfrontPayment}
                />
              )}

              <Button
                isDisabled={!dirty}
                isLoading={isLoading}
                text={t('updateBillingDetails')}
                type='submit'
              />
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};
