export default [
  {
    date: '2024-10-07',
    content: [
      {
        title: 'Merged Accounts',
        bullets: [
          'Simplify account management by accessing multiple facilities with a single email address.',
          'Easily switch between facilities in the "My Accounts" section.,',
        ],
      },
      {
        title: 'Multilingual Contracts and Waivers',
        bullets: [
          'Enhance member experience with contracts and waivers in German and French.',
          'Select the preferred language for your facility.',
        ],
      },
      {
        title: 'Password Link Validity',
        bullets: [
          'We have strengthened security and compliance with 24-hour password link validity.',
        ],
      },
    ],
  },
  {
    date: '2023-04-26',
    content: [
      {
        title: 'German Translation',
        body: 'We have added the ability for our German-speaking users to use Octiv in their native language! This is our first release that caters to a language other than English, so please let us know if you have any feedback.',
      },
      {
        title: 'Removed gender specification requirement',
        body: 'You no longer need to specify your gender when signing up or editing your profile on Octiv.',
      },
    ],
  },
  {
    date: '2023-02-27',
    content: [
      {
        title: 'Location tags',
        body: 'If your gym has multiple rooms within a single location, you can now view what room your class is in if they have set it up.',
      },
    ],
  },
  {
    date: '2022-10-11',
    content: [
      {
        title: 'Replaced Notifications tab with Packages',
        body: 'Managing your packages and sessions should be something that is easy to find and do, so now it is! Notifications can be found in the menu tab by tapping on the bell icon in the top right corner.',
      },
      {
        title: 'Prompt for when you have no sessions available',
        body: "We really did make it difficult for you to attend more classes, didn't we? Now, when you don't have any sessions available to book that class, we'll prompt you to top up/buy a new package.",
      },
      {
        title: 'App Update Prompts',
        body: "In the future, you may receive a prompt that states that you need to download the new version of Octiv. We have done this to ensure that you are always running the latest version of Octiv and don't miss any important updates that we release.",
      },
    ],
  },
  {
    date: '2022-06-23',
    content: [
      {
        title: 'Fix for booking and cancellation thresholds',
        body: 'Thresholds were being applied incorrectly when they were set for over a day.',
      },
    ],
  },
  {
    date: '2022-05-03',
    content: [
      {
        title: 'Maintenance Update',
        body: "General bug fixes and improvements, including enhanced handling of invalid meeting URL's.",
      },
    ],
  },
  {
    date: '2022-03-31',
    content: [
      {
        title: 'Redesigned Tab Bar',
        bullets: [
          "We've combined the Schedule and Workout tabs into a single Facility tab, allowing you to more easily interact with the two features.",
          "Not sure when your next booking is? Take a look, there's a tab for that too.",
        ],
      },
      {
        title: 'The Facility Tab',
        body: 'We did a bit more than just combine the Schedule and Workout tabs:',
        bullets: [
          '"Today" is now highlighted on the date picker.',
          'Navigate to "today" by tapping on the icon in the top right of the screen.',
          'Blue dots will appear below dates that you have a booking on.',
          'The noticeboard message will be visible below the date picker if your facility has one set.',
          'The Whiteboard can now be found within the Workout on days where a workout is available.',
          "We've also made it simpler to like a workout result on the Whiteboard.",
        ],
      },
      {
        title: 'Body Weight Tracking',
        body: 'You can now track your body weight over time by navigating to the Menu and then to Body Weights.',
      },
    ],
  },
];
