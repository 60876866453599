import moment from 'moment';
import {
  Chip,
  Modal,
  ProcessingBar,
  ProcessingSuspense,
  Tooltip,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useEventModal } from 'octiv-context/EventModal';
import {
  useClassDatesDelete,
  useClassDatesFindById,
  useClassDatesUpdate,
} from 'octiv-hooks/requests/ClassDates';
import {
  useClassesCreate,
  useClassesDelete,
  useClassesFindById,
  useClassesUpdate,
} from 'octiv-hooks/requests/Classes';
import { usePackagesFind } from 'octiv-hooks/requests/Packages';
import { useTagsFind } from 'octiv-hooks/requests/Tags';
import { useUsersFindStaff } from 'octiv-hooks/requests/Users';
import { tagsTypes } from 'octiv-utilities/Constants';
import { getDateYearMonthDay } from 'octiv-utilities/Functions';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Event from './event';
import FormEventInstance from './FormEventInstance';
import FormEventMaster from './FormEventMaster';

export default () => {
  const hasAccess = useContext(AccessContext);
  const {
    id,
    isInstructor,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const {
    type,
    eventMasterId,
    eventInstanceId,
    initialEvent,
    onClose,
    setEventModal,
    resetEventModal,
  } = useEventModal();

  const [locationId, setLocationId] = useState();
  const [fromDate, setFromDate] = useState();
  const { t } = useTranslation();

  const generatedToDate = getDateYearMonthDay({
    date: moment().endOf('isoWeek').subtract(1, 'week').add(105, 'days'),
  });

  const onCloseModal = () => {
    resetEventModal();
    if (onClose) onClose();
  };

  const classesFindById = useClassesFindById(
    { id: eventMasterId },
    { enabled: !!eventMasterId }
  );

  const classDatesFindById = useClassDatesFindById(
    { id: eventInstanceId },
    { enabled: !!eventInstanceId }
  );

  const usersFindStaff = useUsersFindStaff(
    {
      filter: {
        isMinimalData: true,
      },
      paging: { perPage: -1 },
    },
    {
      enabled: type !== 'view',
      refetchOnWindowFocus: false,
    }
  );

  const packagesFind = usePackagesFind(
    {
      filter: { isActive: 1 },
      paging: { perPage: -1 },
      typeId: '1,2,3',
    },
    {
      enabled: type !== 'view',
      refetchOnWindowFocus: false,
    }
  );

  const classesCreate = useClassesCreate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: onCloseModal,
  });

  const classesUpdate = useClassesUpdate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: onCloseModal,
  });

  const classesDelete = useClassesDelete({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: onCloseModal,
  });

  const classDatesUpdate = useClassDatesUpdate({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: onCloseModal,
  });

  const classDatesDelete = useClassDatesDelete({
    meta: {
      useOnSuccessToast: true,
    },
    onSuccess: onCloseModal,
  });

  const tagsFind = useTagsFind(
    {
      filter: {
        type: tagsTypes.LOCATION,
        locationId,
      },
    },
    {
      enabled: !!locationId && type !== 'view',
      refetchOnWindowFocus: false,
    }
  );

  const event = React.useMemo(() => {
    if (eventMasterId) {
      return classesFindById.data || {};
    }

    if (eventInstanceId) {
      return classDatesFindById.data || initialEvent || {};
    }

    return initialEvent || {};
  }, [
    eventMasterId,
    eventInstanceId,
    classesFindById.data,
    classDatesFindById.data,
    initialEvent,
  ]);

  React.useEffect(() => {
    if (event) {
      setLocationId(event?.locationId || event?.class?.locationId);
    }
  }, [event]);

  const onPutPostEventMasterRequest = (values) => {
    if (values.tagIds && !values.tagIds[0]) {
      // eslint-disable-next-line no-param-reassign
      values.tagIds = null;
    }

    if (type === 'create') {
      classesCreate.mutate(values);
    } else {
      classesUpdate.mutate({ id: eventMasterId, ...values });
    }
  };

  const onClickAction = ({ action }) => {
    switch (action) {
      case 'editSeries':
        setEventModal({
          type: 'updateMaster',
          eventMasterId: event.class.id,
        });
        setFromDate(event.date);
        break;

      case 'deleteSeries':
        if (window.confirm(t('areYouSureProceed'))) {
          classesDelete.mutate({ id: event.class.id, fromDate: event.date });
        }
        break;

      case 'editOnceOff':
        setEventModal({
          type: 'updateMaster',
          eventMasterId: event.class.id,
        });
        break;

      case 'deleteOnceOff':
        if (window.confirm(t('areYouSureProceed'))) {
          classesDelete.mutate({
            id: event.class.id,
            fromDate: event.date,
          });
        }
        break;

      case 'editInstance':
        setEventModal({
          type: 'updateInstance',
          eventInstanceId,
        });
        break;

      case 'deleteInstance':
        if (window.confirm(t('areYouSureProceed'))) {
          classDatesDelete.mutate({ id: eventInstanceId });
        }
        break;

      case 'duplicate':
        setEventModal({
          type: 'create',
          eventMasterId: event.class.id,
        });
        break;

      case 'print':
        window.open(`/event/${eventInstanceId}/print`);
        break;

      default:
        break;
    }
  };

  let isInstructorOwnSession = false;

  if (isInstructor && event?.class?.isSession && event?.instructor?.id === id) {
    isInstructorOwnSession = true;
  }

  let tooltipOptions = [];
  const baseTooltipOptions = [
    { label: t('print'), value: 'print' },
    ...(!isInstructor || isInstructorOwnSession
      ? [{ label: t('duplicate'), value: 'duplicate' }]
      : []),
  ];

  const hasAccessSingleActions =
    isInstructorOwnSession ||
    hasAccess.featureSchedulingActions ||
    hasAccess.featureSchedulingSingleActions;

  if (hasAccessSingleActions && event?.class?.type?.id === 1) {
    tooltipOptions = [
      { label: t('edit'), value: 'editOnceOff' },
      ...baseTooltipOptions,
      { label: t('delete'), value: 'deleteOnceOff' },
    ];
  } else if (hasAccessSingleActions) {
    tooltipOptions = [
      ...baseTooltipOptions,
      { title: t('instanceActions') },
      {
        label: t('editInstance'),
        value: 'editInstance',
      },
      {
        label: t('deleteInstance'),
        value: 'deleteInstance',
      },
    ];

    if (
      (isInstructorOwnSession || hasAccess.featureSchedulingActions) &&
      event?.class?.type?.id !== 1 &&
      event?.class?.isActive
    ) {
      tooltipOptions = [
        ...tooltipOptions,
        { title: t('seriesActions') },
        { label: t('editSeries'), value: 'editSeries' },
        {
          label: t('deleteSeries'),
          value: 'deleteSeries',
        },
      ];
    }
  } else {
    tooltipOptions = baseTooltipOptions;
  }

  const locationTags = tagsFind?.data?.data || [];

  return type !== undefined ? (
    <>
      {(classesFindById.isFetching ||
        classDatesFindById.isFetching ||
        classesCreate.isLoading ||
        classesUpdate.isLoading ||
        classesDelete.isLoading ||
        classDatesDelete.isLoading) && <ProcessingBar />}

      <Modal
        renderRightTitle={() =>
          type === 'view' && (
            <Tooltip
              options={tooltipOptions}
              onClick={({ value }) => onClickAction({ action: value })}
            >
              <Chip
                hasAlternateBackground
                color='primary'
                icon='more_vertical'
                mr={2}
                title={t('actions')}
              />
            </Tooltip>
          )
        }
        title={
          type === 'create'
            ? t('createEvent')
            : type === 'updateMaster'
            ? t('updateEvent')
            : type === 'updateInstance'
            ? `${t('updateEventInstance')}: ${event.name}`
            : event.name
        }
        onClose={onCloseModal}
      >
        {(!!eventMasterId || !!eventInstanceId) && !event?.id ? (
          <ProcessingSuspense />
        ) : type === 'view' ? (
          <Event data={event} />
        ) : type === 'updateInstance' ? (
          <FormEventInstance
            initialValues={event}
            isFetchingUsersInstructors={usersFindStaff.isFetching}
            isLoading={classDatesUpdate.isLoading}
            locationTags={locationTags}
            usersInstructors={usersFindStaff?.data?.data || []}
            onSubmit={(values) =>
              classDatesUpdate.mutate({ id: eventInstanceId, ...values })
            }
          />
        ) : (
          <FormEventMaster
            fromDate={fromDate}
            generatedToDate={generatedToDate}
            initialValues={event}
            isFetchingPackages={packagesFind.isFetching}
            isFetchingUsersInstructors={usersFindStaff.isFetching}
            isLoading={classesCreate.isLoading || classesUpdate.isLoading}
            locationOptions={locationOptions}
            locationTags={locationTags}
            packages={packagesFind.data?.data || []}
            type={type}
            usersInstructors={usersFindStaff?.data?.data || []}
            onSetLocation={(values) => setLocationId(values.locationId)}
            onSubmit={onPutPostEventMasterRequest}
          />
        )}
      </Modal>
    </>
  ) : null;
};
