import {
  Alert,
  Box,
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Text,
} from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useSignedInUser } from 'octiv-context/SignedInUser';
import { useToggle } from 'octiv-hooks';
import {
  useMandatesCreate,
  useMandatesFindByIdUserLatest,
  useMandatesUpdate,
} from 'octiv-hooks/requests/Mandates';
import {
  useUserTenantsFindByIdPaymentDetails,
  useUserTenantsUpdatePaymentDetails,
} from 'octiv-hooks/requests/UserTenants';
import { getDateReadableDayMonthYear } from 'octiv-utilities/Functions';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Form from './Form';

export default () => {
  const { t } = useTranslation();

  const [toggle, setToggle, resetToggle] = useToggle();
  const { id: userTenantId, tenant } = useActiveUserTenant();
  const signedInUser = useSignedInUser();
  const { id } = signedInUser;
  const { isSepa } = tenant;

  const {
    data: userPaymentDetails,
    isFetching: isFetchingUserPaymentDetails,
    refetch: getUserPaymentDetailsRequest,
  } = useUserTenantsFindByIdPaymentDetails({ id: userTenantId });

  const paymentDetails = userPaymentDetails?.paymentDetails;
  const isDebitOrder = paymentDetails?.userDebitStatus?.id === 2;

  const isVisibleFormPaymentDetails =
    paymentDetails &&
    (isDebitOrder === false ||
      !paymentDetails?.debitDay?.id ||
      !paymentDetails?.accountHolderName ||
      !paymentDetails?.iban ||
      !paymentDetails?.bic ||
      !paymentDetails?.address);

  const {
    isLoading: isUpdatingUserPaymentDetails,
    mutate: putUserPaymentDetailsRequest,
  } = useUserTenantsUpdatePaymentDetails({
    onSuccess: () => {
      getUserPaymentDetailsRequest();
      resetToggle();
    },
  });

  const {
    data: mandate,
    isFetching: isFetchingMandate,
    refetch: getMandateRequest,
    error: mandateError,
  } = useMandatesFindByIdUserLatest(
    {
      id,
    },
    {
      enabled: !!isDebitOrder,
      canShowError: false,
    }
  );

  const { isLoading: isCreatingMandate, mutate: postMandateRequest } =
    useMandatesCreate({
      onSuccess: getMandateRequest,
      onSuccessToast: { title: t('mandateCreated') },
      meta: { useOnSuccessToast: true },
    });

  const { isLoading: isUpdatingMandateSign, mutate: putMandateSignRequest } =
    useMandatesUpdate({
      enabled: !!mandate?.id,
      onSuccess: () => {
        getMandateRequest();
        window.scrollTo(0, 0);
      },
    });

  useEffect(() => {
    if (mandateError?.status === 404) {
      postMandateRequest();
    } else if (mandateError?.messageBody) {
      toast.error(mandateError?.messageBody);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mandateError]);

  const onSubmitForm = (values) => {
    putUserPaymentDetailsRequest({
      id: userTenantId,
      paymentDetails: {
        debitStatusId: 2,
        ...values,
      },
    });
  };

  const renderDetail = ({ label, value, ...props }) => (
    <Box mb={4} {...props}>
      <Text color='grey1' variant='caption'>
        {label}
      </Text>

      <Text>{value}</Text>
    </Box>
  );

  return (
    <>
      {toggle.isVisible && (
        <Modal title={t('updateBillingDetails')} onClose={resetToggle}>
          <Form
            initialValues={paymentDetails}
            isLoading={isUpdatingUserPaymentDetails}
            onSubmit={onSubmitForm}
          />
        </Modal>
      )}

      <Container
        appBarProps={{
          title: isSepa ? t('sepaDirectDebitMandate') : t('mandate'),
        }}
        isLoading={
          isFetchingUserPaymentDetails || isFetchingMandate || isCreatingMandate
        }
      >
        {isVisibleFormPaymentDetails ? (
          <Form
            initialValues={paymentDetails}
            isLoading={isUpdatingUserPaymentDetails}
            onSubmit={onSubmitForm}
          />
        ) : (
          mandate?.status && (
            <>
              {mandate?.status === 'created' && (
                <Alert
                  mb={4}
                  subtitle={t('mandateHasNotBeenSigned')}
                  title={t('pleaseNote')}
                  variant='warning'
                />
              )}

              {mandate?.status === 'active' && (
                <Alert
                  mb={4}
                  subtitle={`${t('signedOn')}: ${getDateReadableDayMonthYear({
                    date: mandate.signedAt,
                  })}`}
                  title={t('mandateHasBeenSigned')}
                />
              )}

              {mandate?.status === 'cancelled' && (
                <Alert
                  buttonRightProps={{
                    text: t('signNewMandate'),
                    onClick: () =>
                      postMandateRequest({
                        userId: id,
                      }),
                  }}
                  mb={4}
                  subtitle={`${t('signedOn')}: ${getDateReadableDayMonthYear({
                    date: mandate?.signedAt,
                  })}\n${t('cancelledOn')}: ${getDateReadableDayMonthYear({
                    date: mandate?.cancelledAt,
                  })}`}
                  title={t('mandateHasBeenCancelled')}
                  variant='danger'
                />
              )}

              <Row>
                <Col md={4}>
                  <Card title={t('details')}>
                    {renderDetail({
                      label: t('mandateReference'),
                      value: mandate?.reference,
                    })}

                    {renderDetail({
                      label: t('tenantName'),
                      value: tenant?.name,
                    })}

                    {/* // TODO: add the address back once we've decided which address to display here
                    - address should be the address of the location that the member is signing up to
                     */}
                    {/* {renderDetail({
                      label: t('tenantAddress'),
                      value: tenant?.address,
                    })} */}

                    <Box bg='grey4' height='1px' my={4} />

                    {renderDetail({
                      label: t('accountHolder'),
                      value: paymentDetails?.accountHolderName,
                    })}

                    {renderDetail({
                      label: t('internationalBankAccountNumber'),
                      value: paymentDetails?.iban,
                    })}

                    {renderDetail({
                      label: t('bankIdentifierCode'),
                      value: paymentDetails?.bic,
                    })}

                    {renderDetail({
                      label: t('address'),
                      value: paymentDetails?.address,
                      mb: 0,
                    })}

                    <Button
                      hasBorder
                      color='grey1'
                      mt={4}
                      scale='small'
                      text={t('updateBillingDetails')}
                      onClick={setToggle}
                    />
                  </Card>
                </Col>

                <Col md={8}>
                  <Card title={t('mandateTerms')}>
                    <Text whiteSpace='pre-line'>
                      {t('mandateTermsContent', {
                        tenantName: tenant?.name,
                      })}
                    </Text>

                    {mandate.status === 'created' && (
                      <>
                        <Box bg='grey4' height='1px' my={4} />

                        <Box isFlex alignItems='center'>
                          <Text variant='heading'>
                            {getDateReadableDayMonthYear({
                              canFallback: true,
                            })}
                          </Text>

                          <Button
                            isLoading={isUpdatingMandateSign}
                            ml='auto'
                            text={t('acceptAndSignMandate')}
                            onClick={() =>
                              putMandateSignRequest({
                                id: mandate?.id,
                                status: 'active',
                              })
                            }
                          />
                        </Box>
                      </>
                    )}

                    <Text
                      color='grey1'
                      mt={4}
                      textAlign='center'
                      variant='caption'
                    >
                      <em>{t('mandateTermsFooter')}</em>
                    </Text>
                  </Card>
                </Col>
              </Row>
            </>
          )
        )}
      </Container>
    </>
  );
};
