import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({ isLoading, initialValues, ...props }) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        logo: initialValues.logo || undefined,
      }}
      validationSchema={Yup.object().shape({
        logo: Yup.string(),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                canSubmitOnChange
                isDrop
                label={t('locationLogo')}
                name='logo'
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
