import * as Sentry from '@sentry/react';
import {
  Alert,
  Box,
  Card,
  Chip,
  Modal,
  ProcessingBar,
  ProcessingSuspense,
  ProgressBar,
  Text,
} from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { useAddBookingsModal } from 'octiv-context/AddBookingsModal';
import { useMediaQuery, useToggle } from 'octiv-hooks';
import {
  useAttendanceRecordsCreateCheckIn,
  useAttendanceRecordsDeleteCancelCheckIn,
} from 'octiv-hooks/requests/AttendanceRecords';
import {
  useClassBookingsCreate,
  useClassBookingsCreateSendAthleteMessage,
  useClassBookingsUpdateCancel,
  useClassBookingsUpdateNoShow,
} from 'octiv-hooks/requests/ClassBookings';
import {
  useClassBookingWaitingCreateSendAthleteMessage,
  useClassBookingWaitingUpdateLeaveWaitingList,
} from 'octiv-hooks/requests/ClassBookingWaiting';
import {
  useClassDatesCreateSendMessageToClassBookings,
  useClassDatesCreateSendMessageToWaitingList,
  useClassDatesFindByIdClassBookingDetails,
} from 'octiv-hooks/requests/ClassDates';
import { getBookingsData } from 'octiv-utilities/Functions';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormCancel from './FormCancel';
import FormMessage from './FormMessage';
import Header from './Header';
import TableAttendees from './TableAttendees';

export const messageTypes = {
  memberBooking: 'messageMemberBooking',
  memberWaitingList: 'messageMemberWaitingList',
  eventBooking: 'messageEventBooking',
  eventWaitingList: 'messageEventWaitingList',
};

export default ({
  data: {
    id: eventId,
    instructor,
    date,
    endTime,
    limit,
    startTime,
    supportingInstructor,
    tags,
    class: { isActive, location, isVirtual },
  },
}) => {
  const { t } = useTranslation();

  const { xsDown, mdDown } = useMediaQuery();

  const hasAccess = useContext(AccessContext);
  const {
    tenant: { locations },
  } = useActiveUserTenant();
  const { setAddBookingsModal } = useAddBookingsModal();

  const [
    toggleBookingCancel,
    setToggleBookingCancel,
    resetToggleBookingCancel,
  ] = useToggle();
  const [toggleMessage, setToggleMessage, resetToggleMessage] = useToggle();

  const [bookings, setBookings] = useState();
  const [canDisplayExtraDetailsInClass, setCanDisplayExtraDetailsInClass] =
    useState(hasAccess.featureSchedulingExtraDetailsInClass);

  const healthProviders =
    locations.find((item) => item.id === location.id)?.healthProviders || [];

  const classDatesFindByIdClassBookingDetails =
    useClassDatesFindByIdClassBookingDetails(
      { id: eventId, append: 'active_user_packages' },
      {
        onSuccess: (response) =>
          setBookings(getBookingsData({ allBookings: response })),
      }
    );

  const attendanceRecordsCreateCheckIn = useAttendanceRecordsCreateCheckIn();

  const attendanceRecordsDeleteCancelCheckIn =
    useAttendanceRecordsDeleteCancelCheckIn();

  const classBookingsUpdateNoShow = useClassBookingsUpdateNoShow();

  const classBookingsUpdateCancel = useClassBookingsUpdateCancel({
    onSuccess: resetToggleBookingCancel,
  });

  const classBookingWaitingUpdateLeaveWaitingList =
    useClassBookingWaitingUpdateLeaveWaitingList();

  const classBookingsCreateSendAthleteMessage =
    useClassBookingsCreateSendAthleteMessage({
      onSuccess: resetToggleMessage,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const classBookingWaitingCreateSendAthleteMessage =
    useClassBookingWaitingCreateSendAthleteMessage({
      onSuccess: resetToggleMessage,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const classDatesCreateSendMessageToClassBookings =
    useClassDatesCreateSendMessageToClassBookings({
      onSuccess: resetToggleMessage,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const classDatesCreateSendMessageToWaitingList =
    useClassDatesCreateSendMessageToWaitingList({
      onSuccess: resetToggleMessage,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const classBookingsCreate = useClassBookingsCreate();

  useEffect(() => {
    if (hasAccess.featureSchedulingExtraDetailsInClass) {
      (async () => {
        try {
          const localCanDisplayExtraDetailsInClass = await localStorage.getItem(
            'canDisplayExtraDetailsInClass'
          );

          setCanDisplayExtraDetailsInClass(
            localCanDisplayExtraDetailsInClass === null
              ? !mdDown
              : localCanDisplayExtraDetailsInClass === 'true'
          );
        } catch (error) {
          Sentry.captureException(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleCanDisplayExtraDetailsInClass = async () => {
    try {
      let updatedValue;

      setCanDisplayExtraDetailsInClass((prev) => {
        updatedValue = !prev;
        return updatedValue;
      });

      await localStorage.setItem('canDisplayExtraDetailsInClass', updatedValue);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onSubmitMessage = (values) => {
    switch (toggleMessage.type) {
      case messageTypes.memberBooking:
        classBookingsCreateSendAthleteMessage.mutate({
          id: toggleMessage.data.bookingId,
          ...values,
        });
        break;

      case messageTypes.memberWaitingList:
        classBookingWaitingCreateSendAthleteMessage.mutate({
          id: toggleMessage.data.bookingId,
          ...values,
        });
        break;

      case messageTypes.eventBooking:
        classDatesCreateSendMessageToClassBookings.mutate({
          id: eventId,
          ...values,
        });
        break;

      case messageTypes.eventWaitingList:
        classDatesCreateSendMessageToWaitingList.mutate({
          id: eventId,
          ...values,
        });
        break;

      default:
        break;
    }
  };

  const onClickAction = ({ action, id, user }) => {
    switch (action) {
      case messageTypes.memberBooking:
      case messageTypes.memberWaitingList:
        setToggleMessage({ type: action, data: { bookingId: id, ...user } });
        break;

      case 'bookingCheckIn':
        attendanceRecordsCreateCheckIn.mutate({ classBookingId: id });
        break;

      case 'bookingCheckInCancel':
        attendanceRecordsDeleteCancelCheckIn.mutate({ classBookingId: id });
        break;

      case 'bookingNoShow':
        classBookingsUpdateNoShow.mutate({ id });
        break;

      case 'bookingCancel':
        setToggleBookingCancel({ data: { id } });
        break;

      case 'bookingWaitingCancel':
        classBookingWaitingUpdateLeaveWaitingList.mutate({ id });
        break;

      case 'bookingWaitingConvertToBooking':
        classBookingsCreate.mutate({
          classDateId: eventId,
          userId: user.id,
        });
        break;

      default:
        break;
    }
  };

  const padding = xsDown ? 3 : mdDown ? 4 : 6;

  return (
    <>
      {(classBookingWaitingUpdateLeaveWaitingList.isLoading ||
        attendanceRecordsCreateCheckIn.isLoading ||
        attendanceRecordsDeleteCancelCheckIn.isLoading ||
        classBookingsUpdateNoShow.isLoading ||
        classBookingsCreateSendAthleteMessage.isLoading ||
        classBookingWaitingCreateSendAthleteMessage.isLoading ||
        classDatesCreateSendMessageToClassBookings.isLoading ||
        classDatesCreateSendMessageToWaitingList.isLoading ||
        classBookingsCreate.isLoading) && <ProcessingBar />}

      {toggleBookingCancel.isVisible && (
        <Modal
          isCondensed
          title={t('cancelBooking')}
          onClose={resetToggleBookingCancel}
        >
          <FormCancel
            initialValues={toggleBookingCancel.data}
            isLoading={classBookingsUpdateCancel.isLoading}
            onSubmit={(values) => classBookingsUpdateCancel.mutate(values)}
          />
        </Modal>
      )}

      {toggleMessage.isVisible && (
        <Modal isSidebar title={t('sendMessage')} onClose={resetToggleMessage}>
          <Alert mb={4} subtitle={t('sendBothEmailAndPush')} variant='info' />

          <FormMessage
            isLoading={
              classBookingsCreateSendAthleteMessage.isLoading ||
              classBookingWaitingCreateSendAthleteMessage.isLoading ||
              classDatesCreateSendMessageToClassBookings.isLoading ||
              classDatesCreateSendMessageToWaitingList.isLoading
            }
            to={
              toggleMessage.type === messageTypes.memberBooking ||
              toggleMessage.type === messageTypes.memberWaitingList
                ? toggleMessage.data.name
                : toggleMessage.type === messageTypes.eventBooking
                ? `All ${t('booked')}`
                : `All ${t('waitingList')}`
            }
            onSubmit={onSubmitMessage}
          />
        </Modal>
      )}

      {!isActive && (
        <Alert
          mb={4}
          subtitle={t('youMayEditClassInstance')}
          title={t('inactiveClassSeries')}
          variant='warning'
        />
      )}

      <Header
        date={date}
        endTime={endTime}
        location={location}
        startTime={startTime}
        supportingTrainer={supportingInstructor}
        tags={tags}
        trainer={instructor}
      />

      {!bookings && classDatesFindByIdClassBookingDetails.isFetching && (
        <ProcessingSuspense mt={4} />
      )}

      {bookings && (
        <>
          <Box isFlex flexWrap='wrap' justifyContent='flex-end' mb={4} mt={2}>
            <Chip
              hasAlternateBackground
              icon='add'
              mt={2}
              title={t('addBookings')}
              onClick={() => setAddBookingsModal({ eventId })}
            />

            {bookings.bookedCount > 0 && (
              <Chip
                hasAlternateBackground
                icon='email'
                ml={2}
                mt={2}
                title={t('messageBooked')}
                onClick={() =>
                  setToggleMessage({ type: messageTypes.eventBooking })
                }
              />
            )}

            {bookings.waitingListCount > 0 && (
              <Chip
                hasAlternateBackground
                icon='email'
                ml={2}
                mt={2}
                title={t('messageWaitingList')}
                onClick={() =>
                  setToggleMessage({ type: messageTypes.eventWaitingList })
                }
              />
            )}

            {hasAccess.featureSchedulingExtraDetailsInClass && (
              <Chip
                hasAlternateBackground
                icon={
                  canDisplayExtraDetailsInClass
                    ? 'visibility_off'
                    : 'visibility'
                }
                ml={2}
                mt={2}
                title={`${
                  canDisplayExtraDetailsInClass ? t('hide') : t('show')
                } ${t('packageDetail')}`}
                onClick={onToggleCanDisplayExtraDetailsInClass}
              />
            )}
          </Box>

          {isVirtual && healthProviders.find((item) => item.id === 1) ? (
            <Alert
              mb={4}
              subtitle={t('vitalityRightsReserved')}
              variant='warning'
            />
          ) : null}

          <Card hasBorder hasPaddingChildren={false} mb={4}>
            <Box
              hasRadiusTop
              alignItems='flex-end'
              isFlex={!xsDown}
              justifyContent='space-between'
              p={padding}
              pb={2}
            >
              <Text
                isBold
                lineHeight='normal'
                variant={xsDown ? 'subheading' : 'heading'}
              >
                {`${t('booked')}: ${bookings.bookedCount}/${limit}`}
              </Text>

              <Text
                isBold
                color='grey1'
                lineHeight='normal'
                variant={xsDown ? 'body' : 'subheading'}
              >
                {`${t('checkedIn')}: ${bookings.bookedCountCheckedIn} - ${t(
                  'noShows'
                )}: ${bookings.bookedCountNoShows}`}
              </Text>
            </Box>

            <ProgressBar
              boxContainerProps={{ mx: padding }}
              width={
                (bookings.bookedCount / limit) * 100 > 100
                  ? '100%'
                  : `${(bookings.bookedCount / limit) * 100}%`
              }
            />

            <TableAttendees
              canDisplayExtraDetailsInClass={canDisplayExtraDetailsInClass}
              data={bookings.booked}
              date={date}
              isLoading={classDatesFindByIdClassBookingDetails.isFetching}
              onClickAction={onClickAction}
            />
          </Card>

          {bookings.waitingListCount > 0 && (
            <Card
              hasBorder
              hasPaddingChildren={false}
              mb={4}
              title={`${t('waitingList')}: ${bookings.waitingListCount}`}
            >
              <TableAttendees
                isWaitingList
                canDisplayExtraDetailsInClass={canDisplayExtraDetailsInClass}
                data={bookings.waitingList}
                date={date}
                isLoading={classDatesFindByIdClassBookingDetails.isFetching}
                onClickAction={onClickAction}
              />
            </Card>
          )}

          {bookings.lateCancellationsCount > 0 && (
            <Card
              hasBorder
              hasPaddingChildren={false}
              title={`${t('lateCancellations')}: ${
                bookings.lateCancellationsCount
              }`}
            >
              <TableAttendees
                isLateCancellations
                canDisplayExtraDetailsInClass={canDisplayExtraDetailsInClass}
                data={bookings.lateCancellations}
                date={date}
                isLoading={classDatesFindByIdClassBookingDetails.isFetching}
              />
            </Card>
          )}
        </>
      )}
    </>
  );
};
