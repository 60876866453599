import { Formik } from 'formik';
import { Col, Field, Row } from 'octiv-components';
import { generateYears, getFullName } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  locationOptions,
  classDates,
  isFetchingClassDates,
  isFetchingMembers,
  members,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      {...props}
      validationSchema={Yup.object().shape({
        locationId: Yup.string().required(t('required')),
        classId: Yup.string(),
        userId: Yup.string(),
        bookingStatusId: Yup.string().required(t('required')),
        year: Yup.string().required(t('required')),
      })}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Row>
            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('location')}
                name='locationId'
                options={locationOptions}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingClassDates}
                label={t('class')}
                name='classId'
                options={[
                  { label: t('all'), value: undefined },
                  ...classDates.map((item) => ({
                    label: `${item.name} (${item.startTime} - ${item.endTime})`,
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                isLoading={isFetchingMembers}
                label={t('member')}
                name='userId'
                options={[
                  { label: t('all'), value: undefined },
                  ...members.map((item) => ({
                    label: getFullName(item),
                    value: item.id,
                  })),
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('bookingStatus')}
                name='bookingStatusId'
                options={[
                  { label: t('booked'), value: 1 },
                  { label: t('checkedIn'), value: 6 },
                  { label: t('cancelledByMember'), value: 2 },
                  { label: t('cancelledByTrainer'), value: 4 },
                  { label: t('cancelledAfterThreshold'), value: 3 },
                  { label: t('noShow'), value: 5 },
                ]}
              />
            </Col>

            <Col lg={4} md={6}>
              <Field
                canSubmitOnChange
                hasAlternateBackground
                isSelect
                label={t('year')}
                name='year'
                options={generateYears({ isOptions: true })}
              />
            </Col>
          </Row>
        </form>
      )}
    </Formik>
  );
};
