import { Table } from 'octiv-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, ...props }) => {
  const { t } = useTranslation();

  const tableData = useMemo(() => data || [], [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('Programme'),
        accessor: 'programme',
      },
      {
        Header: t('Name'),
        accessor: 'name',
      },
      {
        Header: t('Date'),
        accessor: 'date',
      },
      {
        Header: t('Warm Up'),
        accessor: 'warmUp',
      },
      {
        Header: t('Cool Down'),
        accessor: 'coolDown',
      },
      {
        Header: t('Member Notes'),
        accessor: 'memberNotes',
      },
      {
        Header: t('Trainer Notes'),
        accessor: 'coachNotes',
      },
      {
        Header: t('Part A - Type'),
        accessor: 'partAType',
      },
      {
        Header: t('Part A - Part'),
        accessor: 'partAPart',
      },
      {
        Header: t('Part A - Category'),
        accessor: 'partACategory',
      },
      {
        Header: t('Part A - Type'),
        accessor: 'partABenchmark',
      },
      {
        Header: t('Part A - Name'),
        accessor: 'partAName',
      },
      {
        Header: t('Part A - Description'),
        accessor: 'partADescription',
      },
      {
        Header: t('Part A - Measurement'),
        accessor: 'partAMeasurement',
      },
      {
        Header: t('Part A - Resource URL'),
        accessor: 'partAResourceURL',
      },
    ],
    [t]
  );

  return <Table {...props} columns={tableColumns} data={tableData} />;
};
