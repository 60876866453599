import { Box, Button, Text } from 'octiv-components';
import useWidgetTenant from 'octiv-hooks/useWidgetTenant';
import { toCurrency } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { packageTypes } from '../../settings/packages/manage/FormPackage';

export default ({
  item,
  isChosen,
  boxContainerProps,
  isVisibleSelect = true,
  onClickSelect,
}) => {
  const { t } = useTranslation();

  const tenant = useWidgetTenant();
  const { name, description, price, limit, type } = item;
  const code = tenant?.memberBillingCurrency?.code;

  return (
    <Box hasRadius bg='grey5' px={4} py={2} {...boxContainerProps}>
      <Box isFlex alignItems='center' minHeight={10}>
        <Box mr='auto' pr={2}>
          <Text variant='subheading'>{name}</Text>

          {!!type?.id && (
            <Text color='grey1' variant='caption'>
              {`${toCurrency({ value: price, code })} - ${
                limit === 0 ? t('unlimited') : limit
              } ${t('credits')}${
                type.id !== packageTypes.LIMITED
                  ? ` ${
                      type.id === packageTypes.WEEKLY
                        ? t('perWeek')
                        : t('perMonth')
                    }`
                  : ''
              }`}
            </Text>
          )}
        </Box>

        {isVisibleSelect && (
          <Button
            color={isChosen ? 'grey1' : 'primary'}
            hasBorder={isChosen}
            minHeight={8}
            minWidth={null}
            text={isChosen ? t('deselect') : t('select')}
            onClick={() => onClickSelect({ item, isChosen })}
          />
        )}
      </Box>

      {description && (
        <Text color='grey1' mt={1} variant='caption'>
          {description}
        </Text>
      )}
    </Box>
  );
};
