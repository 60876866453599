import { Formik } from 'formik';
import { Button, Col, Field, Row, Step } from 'octiv-components';
import { getLabelsAndValuesFromNumber } from 'octiv-utilities/Functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const packageTypes = {
  LIMITED: 3,
  WEEKLY: 2,
  MONTHLY: 1,
};

export default ({
  initialValues = {},
  isCreate,
  isFetchingPackageTags,
  isLoading,
  packageTags,
  ...props
}) => {
  const { t } = useTranslation();

  const numberValidation = Yup.number().typeError(t('mustBeANumber'));

  return (
    <Formik
      {...props}
      initialValues={{
        name: initialValues.name || undefined,
        description: initialValues.description || undefined,
        price: initialValues.price || undefined,
        topupPrice: initialValues.topupPrice || undefined,
        lateCancellationFee: initialValues.lateCancellationFee || undefined,
        noShowFee: initialValues.noShowFee || undefined,
        typeId: initialValues.typeId || undefined,
        limit:
          initialValues.limit !== undefined
            ? initialValues.limit.toString()
            : undefined,
        defaultPeriodType: initialValues.defaultPeriodType || undefined,
        defaultPeriod: initialValues.defaultPeriod || undefined,
        tagIds: initialValues.tags?.[0]
          ? initialValues.tags.map(({ id }) => id)
          : undefined,
        priority: initialValues.priority || undefined,
        isDisplayed: initialValues.isDisplayOnSignUp === 1,
        isDisplayOnBuyPackages: initialValues.isDisplayOnBuyPackages === 1,
        isHidden: initialValues.isHidden === 1,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('required')),
        description: Yup.string(),
        price: numberValidation.required(t('required')),
        topupPrice: numberValidation,
        lateCancellationFee: Yup.number(),
        noShowFee: Yup.number(),
        typeId: Yup.string().required(t('required')),
        limit: Yup.number()
          .when('typeId', {
            is: (value) => value === '3',
            then: Yup.number()
              .min(1, t('mustBeGreaterThanZero'))
              .typeError(t('mustBeANumber')),
            otherwise: Yup.number()
              .min(0, t('mustBeZeroOrGreater'))
              .typeError(t('mustBeANumber')),
          })
          .required(t('required')),
        defaultPeriodType: Yup.string(),
        defaultPeriod: Yup.string().when('defaultPeriodType', {
          is: (value) => !!value,
          then: Yup.string().required(t('required')),
        }),
        tagIds: Yup.array(),
        priority: numberValidation,
        isDisplayed: Yup.bool().required(t('required')),
        isDisplayOnBuyPackages: Yup.bool().required(t('required')),
        isHidden: Yup.bool().required(t('required')),
      })}
    >
      {({
        handleSubmit,
        setFieldTouched,
        setFieldValue,
        errors,
        values: { typeId, defaultPeriodType, limit },
      }) => (
        <form onSubmit={handleSubmit}>
          <Step
            hasBorder
            boxContainerProps={{ mb: 4 }}
            title={t('packageDetails')}
            value='1'
          >
            <Row>
              <Col>
                <Field label={t('name')} name='name' />
              </Col>

              <Col>
                <Field
                  as='textarea'
                  label={t('description')}
                  name='description'
                />
              </Col>

              <Col md={6}>
                <Field
                  isSelect
                  isDisabled={!isCreate}
                  label={t('packageType')}
                  name='typeId'
                  options={[
                    {
                      label: t('limitedOnceOffSessions'),
                      value: packageTypes.LIMITED,
                    },
                    {
                      label: t('weeklyRecurring'),
                      value: packageTypes.WEEKLY,
                    },
                    {
                      label: t('monthlyRecurring'),
                      value: packageTypes.MONTHLY,
                    },
                  ]}
                />
              </Col>

              {!!typeId && (
                <Col md={6}>
                  <Field
                    helper={
                      typeId !== packageTypes.LIMITED && t('zeroForUnlimited')
                    }
                    isDisabled={!isCreate}
                    label={`${t('howManySessionsIncludedInPackage')}${
                      typeId === packageTypes.WEEKLY
                        ? ` ${t('perWeek').toLowerCase()}`
                        : typeId === packageTypes.MONTHLY
                        ? ` ${t('perMonth').toLowerCase()}`
                        : ''
                    }?`}
                    name='limit'
                  />
                </Col>
              )}
            </Row>
          </Step>

          {!!limit && !errors.limit && (
            <Step
              hasBorder
              boxContainerProps={{ mb: 4 }}
              title={t('packagePricingPeriod')}
              value='2'
            >
              <Row>
                <Col md={6}>
                  <Field label={t('price')} name='price' />
                </Col>

                <Col md={6}>
                  <Field
                    canSetEmptyStringUndefined
                    helper={
                      limit === '0'
                        ? t('unlimitedPackagesCannotBeToppedUp')
                        : t('topUpPriceIsPriceForEachAdditionalSession')
                    }
                    isDisabled={limit === '0'}
                    label={t('topUpPricePerSession')}
                    name='topupPrice'
                  />
                </Col>

                <Col md={6}>
                  <Field
                    isSelect
                    doFinally={() => {
                      setFieldTouched('defaultPeriod', false);
                      setFieldValue('defaultPeriod', undefined);
                    }}
                    helper={t('defaultPeriodTypeHelper')}
                    label={t('defaultPeriodType')}
                    name='defaultPeriodType'
                    options={[
                      { label: t('noDefaultPeriod'), value: undefined },
                      { label: t('weeks'), value: 'weeks' },
                      { label: t('months'), value: 'months' },
                    ]}
                  />
                </Col>

                <Col md={6}>
                  <Field
                    isSelect
                    isDisabled={!defaultPeriodType}
                    label={t('defaultPeriod')}
                    name='defaultPeriod'
                    options={getLabelsAndValuesFromNumber({
                      numberFrom: 1,
                      numberTo: defaultPeriodType === 'weeks' ? 52 : 36,
                    })}
                  />
                </Col>

                <Col md={6}>
                  <Field
                    canSetEmptyStringUndefined
                    helper={t('lateCancellationFeeHelper')}
                    label={t('lateCancellationFee')}
                    name='lateCancellationFee'
                  />
                </Col>

                <Col md={6}>
                  <Field
                    canSetEmptyStringUndefined
                    helper={t('noShowFeeHelper')}
                    label={t('noShowFee')}
                    name='noShowFee'
                  />
                </Col>
              </Row>
            </Step>
          )}

          {!!limit && !errors.limit && (
            <Step
              hasBorder
              boxContainerProps={{ mb: 4 }}
              title={t('packageVisibility')}
              value='3'
            >
              <Row>
                <Col md={6}>
                  <Field
                    isMulti
                    isSelect
                    helper={t('categoriesHelper')}
                    isLoading={isFetchingPackageTags}
                    label={t('categories')}
                    name='tagIds'
                    options={packageTags?.data?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </Col>

                <Col md={6}>
                  <Field
                    helper={t('priorityHelper')}
                    label={t('priority')}
                    name='priority'
                  />
                </Col>

                <Col>
                  <Field
                    isCheckbox
                    name='isDisplayed'
                    options={[
                      {
                        label: t('displayPackageOnSignUpWidget'),
                        value: true,
                      },
                    ]}
                  />
                </Col>

                <Col>
                  <Field
                    isCheckbox
                    name='isDisplayOnBuyPackages'
                    options={[
                      {
                        label: t('allowPurchaseOfPackageThroughApp'),
                        value: true,
                      },
                    ]}
                  />
                </Col>

                <Col>
                  <Field
                    isCheckbox
                    helper={t('preventStaffFromAssigningPackageHelper')}
                    name='isHidden'
                    options={[
                      {
                        label: t('preventStaffFromAssigningPackage'),
                        value: true,
                      },
                    ]}
                  />
                </Col>

                <Col>
                  <Button
                    isLoading={isLoading}
                    text={t('submit')}
                    type='submit'
                  />
                </Col>
              </Row>
            </Step>
          )}
        </form>
      )}
    </Formik>
  );
};
