import { Alert, Container, Modal, Step, Text } from 'octiv-components';
import RadioCheckbox from 'octiv-components/field/RadioCheckbox';
import { ValidateUser } from 'octiv-containers';
import { useQuery, useToggle } from 'octiv-hooks';
import { useBanksFind } from 'octiv-hooks/requests/Banks';
import {
  usePackagesFind,
  usePackagesFindByIdLocations,
} from 'octiv-hooks/requests/Packages';
import { useProgrammesFind } from 'octiv-hooks/requests/Programmes';
import { useUsersCreateSignup } from 'octiv-hooks/requests/Users';
import useWidgetTenant from 'octiv-hooks/useWidgetTenant';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FullscreenProcessingSpinner from '../components/FullscreenProcessingSpinner';
import WidgetHeader from '../components/Header';
import FormDetails from './FormDetails';
import FormLocationPackage from './FormLocationPackage';

export default ({ isPackagesOnly = false }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const publicToken = useQuery('publicToken');
  const packageId = useQuery('packageId');
  const tagId = useQuery('tagId');
  const isSinglePackage = !!packageId;

  const [toggleTerms, setToggleTerms, resetToggleTerms] = useToggle();

  const [hasOctivAccount, setHasOctivAccount] = useState();
  const [user, setUser] = useState();
  const isUser = !!user?.id;

  const [chosenLocationId, setChosenLocationId] = useState();
  const [chosenPackage, setChosenPackage] = useState();

  const [formLocationOptions, setFormLocationOptions] = useState();
  const isSingleLocation = React.useMemo(
    () => formLocationOptions?.length === 1,
    [formLocationOptions]
  );

  const { tenantId, locationOptions, isGoCardless, isSepa, ...tenant } =
    useWidgetTenant();

  React.useEffect(() => {
    if (locationOptions?.[0]) {
      setFormLocationOptions(locationOptions);
    }
  }, [locationOptions]);

  React.useEffect(() => {
    if (isSingleLocation && formLocationOptions?.[0]) {
      setChosenLocationId(formLocationOptions[0].value);
    }
  }, [isSingleLocation, formLocationOptions]);

  const packagesFind = usePackagesFind(
    {
      filter: {
        tenantId,
        ...(isSinglePackage
          ? { packageId }
          : {
              isActive: 1,
              isHidden: 0,
              isForSignUp: 1,
              typeId: '1,2,3',
              locationId: chosenLocationId,
              tagIds: tagId ? [tagId] : undefined,
            }),
      },
      paging: { perPage: -1 },
    },
    {
      enabled: !!tenantId && (isSinglePackage ? true : !!chosenLocationId),
      onSuccess: (response) => {
        if (isSinglePackage && response?.data[0]) {
          setChosenPackage(response?.data[0]);
        }
      },
    }
  );

  const packagesFindByIdLocations = usePackagesFindByIdLocations(
    {
      id: chosenPackage?.id,
    },
    {
      enabled: !!tenantId && isSinglePackage && !!chosenPackage?.id,
      onSuccess: (response) => {
        if (response?.data[0]) {
          const checkedLocations = response.data
            ?.filter((item) => item.isChecked)
            .map((item) => ({
              label: item.name,
              value: item.id,
            }));

          setFormLocationOptions(checkedLocations);

          if (checkedLocations?.length === 1) {
            setChosenLocationId(checkedLocations[0].value);
          }
        }
      },
    }
  );

  const banksFind = useBanksFind(undefined, {
    enabled: !!tenantId && !isGoCardless && !isSepa && !isPackagesOnly,
  });

  const { data: programmes, isFetching: isFetchingProgrammes } =
    useProgrammesFind(
      {
        filter: {
          tenantId: tenant?.id,
          packageId: chosenPackage?.id,
          isActive: 1,
        },
      },
      {
        enabled: !!tenantId && !!chosenPackage?.id,
      }
    );

  const { isLoading: isCreatingMember, mutate: postMemberRequest } =
    useUsersCreateSignup({
      onSuccess: ({
        invoiceId,
        locationPaymentGatewayId,
        redirectUrl,
        link,
      } = {}) => {
        if (link) {
          window.location.assign(link);
        } else if (invoiceId) {
          navigate(
            `/payment/${invoiceId}${
              locationPaymentGatewayId ? `?gid=${locationPaymentGatewayId}` : ''
            }`,
            { replace: true }
          );
        } else if (redirectUrl) {
          window.location.assign(redirectUrl);
        } else {
          navigate(`/download`, { replace: true });
        }
      },
    });

  const onSubmitFormDetails = (values) => {
    const payload = {
      publicToken,
      locationId: chosenLocationId,
      packageId: chosenPackage.id,
      tenantId,
      paymentDetails: values?.paymentDetails,
      termsAndConditionsForContract: values.termsAndConditionsForContract,
      termsAndConditionsForWaiver: values.termsAndConditionsForWaiver,
      ...(user?.id ? { userId: user.id } : {}),
      ...values,
    };

    postMemberRequest(payload);
  };

  if (!tenantId) {
    return <FullscreenProcessingSpinner />;
  }

  return (
    <>
      {toggleTerms.isVisible && (
        <Modal
          title={toggleTerms.type === 'contract' ? t('contract') : t('waiver')}
          onClose={resetToggleTerms}
        >
          <Text
            as='div'
            dangerouslySetInnerHTML={{
              __html:
                toggleTerms.type === 'contract'
                  ? tenant.contractTermsAndConditions
                  : tenant.waiverTermsAndConditions,
            }}
          />
        </Modal>
      )}

      <Container isLoading={packagesFind.isFetching}>
        <WidgetHeader tenant={tenant} />

        {!isPackagesOnly && (
          <Step
            boxContainerProps={{ mb: 4 }}
            title='Your Octiv Account'
            value='1'
          >
            <RadioCheckbox
              isRadio
              options={[
                { label: 'Yes, I have an account', value: true },
                { label: 'No, I do not have an account', value: false },
              ]}
              renderLabel={() => (
                <Text color='grey1'>Do you already have an Octiv account?</Text>
              )}
              value={hasOctivAccount}
              onChange={setHasOctivAccount}
            />

            {hasOctivAccount === true ? (
              <>
                <Text mb={2} mt={4}>
                  Great, let&apos;s verify your Octiv account before proceeding:
                </Text>

                <ValidateUser
                  isPublicContext
                  skipHasLeadUserTenantCheck
                  setUser={setUser}
                  tenantId={tenantId}
                  user={user}
                />
              </>
            ) : hasOctivAccount === false ? (
              <Alert
                mt={4}
                // TODO: translations
                subtitle='Not to worry, you will create your account during this process'
                variant='info'
              />
            ) : null}
          </Step>
        )}

        {(isPackagesOnly || isUser || hasOctivAccount === false) && (
          <>
            <FormLocationPackage
              isFetchingPackageLocations={packagesFindByIdLocations.isFetching}
              isFetchingPackages={packagesFind.isFetching}
              isPackagesOnly={isPackagesOnly}
              isSingleLocation={isSingleLocation}
              isSinglePackage={isSinglePackage}
              locationOptions={formLocationOptions}
              packages={packagesFind.data?.data || []}
              tagId={tagId}
              onGetPackagesRequest={(locationId) =>
                setChosenLocationId(locationId)
              }
              onSubmit={(values) => {
                setChosenLocationId(values.chosenLocationId);
                setChosenPackage(values.chosenPackage);
              }}
            />

            {!!chosenLocationId && !!chosenPackage?.id && (
              <Step
                boxContainerProps={{ mb: 4 }}
                id='memberDetails'
                title={t('yourDetails')}
                value='3'
              >
                <FormDetails
                  banks={banksFind.data || []}
                  chosenPackage={chosenPackage}
                  isFetchingBanks={banksFind.isFetching}
                  isFetchingProgrammes={isFetchingProgrammes}
                  isGoCardless={isGoCardless}
                  isLoading={isCreatingMember}
                  isSepa={isSepa}
                  isUser={isUser}
                  locationOptions={locationOptions}
                  programmes={programmes?.data || []}
                  signUpDebitDayOptions={tenant.signUpDebitDayOptions}
                  signUpPaymentOptions={tenant.signUpPaymentOptions}
                  signUpRequiredFields={tenant.signUpRequiredFields}
                  useContractWaiver={tenant.useContractWaiver}
                  onClickTerms={setToggleTerms}
                  onSubmit={onSubmitFormDetails}
                />
              </Step>
            )}
          </>
        )}
      </Container>
    </>
  );
};
