import { Table, Text } from 'octiv-components';
import { AccessContext } from 'octiv-context';
import { useMemberModal } from 'octiv-context/MemberModal';
import {
  canSetMemberModal,
  getDateReadableDayMonthYear,
  getFullName,
} from 'octiv-utilities/Functions';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ data, isNew, ...props }) => {
  const { t } = useTranslation();

  const hasAccess = useContext(AccessContext);
  const { setMemberModal } = useMemberModal();

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(
    () => [
      {
        Header: t('member'),
        id: 'member',
        Cell: ({ row: { original } }) => (
          <Text
            as='span'
            onClick={
              canSetMemberModal({ userTenant: original?.userTenant, hasAccess })
                ? () =>
                    setMemberModal({ userTenantId: original?.userTenant?.id })
                : null
            }
          >
            {getFullName(original)}
          </Text>
        ),
      },
      {
        Header: t('email'),
        accessor: 'email',
      },
      {
        Header: isNew ? t('dateJoined') : t('deactivatedOn'),
        accessor: isNew ? 'effectiveDate' : 'deactivatedOn',
        Cell: ({ value }) => getDateReadableDayMonthYear({ date: value }),
      },
    ],
    [isNew, setMemberModal, hasAccess, t]
  );

  return (
    <Table
      {...props}
      hasAlternateBackground
      columns={tableColumns}
      data={tableData}
    />
  );
};
