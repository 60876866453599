import { Box, Chip, Divider, Modal, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  usePackagesCreate,
  usePackagesFind,
  usePackagesFindByIdClasses,
  usePackagesFindByIdLocations,
  usePackagesFindByIdProgrammes,
  usePackagesUpdate,
  usePackagesUpdateClasses,
  usePackagesUpdateLocations,
  usePackagesUpdateProgrammes,
} from 'octiv-hooks/requests/Packages';
import { useTagsFind } from 'octiv-hooks/requests/Tags';
import { tagsTypes } from 'octiv-utilities/Constants';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import FormEvents from './FormEvents';
import FormEventsQuery from './FormEventsQuery';
import FormLocations from './FormLocations';
import FormPackage from './FormPackage';
import FormPackageQuery from './FormPackageQuery';
import FormProgrammes from './FormProgrammes';
import Table from './Table';

export default ({ publicToken }) => {
  const { t } = useTranslation();

  const [paging, setPaging] = usePaging();

  const {
    tenantId,
    tenant: { locationOptions },
  } = useActiveUserTenant();
  const [togglePackage, setTogglePackage, resetTogglePackage] = useToggle();
  const [toggleEvents, setToggleEvents, resetToggleEvents] = useToggle();
  const [toggleProgrammes, setToggleProgrammes, resetToggleProgrammes] =
    useToggle();
  const [toggleLocations, setToggleLocations, resetToggleLocations] =
    useToggle();
  const [eventsLocationId, setEventsLocationId] = useState(undefined);

  const [query, setQuery] = useState({
    isActive: true,
  });

  const packagesFind = usePackagesFind({
    filter: {
      isActive: Number(query.isActive),
      typeId: '1,2,3',
    },
    paging,
  });

  const {
    isLoading: isLoadingPackagesCreate,
    mutate: postPackagesCreateRequest,
  } = usePackagesCreate({
    onSuccess: () => {
      resetTogglePackage();
    },
    meta: {
      useOnSuccessToast: true,
    },
  });

  const {
    isLoading: isLoadingPackagesUpdate,
    mutate: putPackagesUpdateRequest,
  } = usePackagesUpdate({
    onSuccess: resetTogglePackage,
    meta: {
      useOnSuccessToast: true,
    },
  });
  const { data: packageEvents, isFetching: isFetchingPackageEvents } =
    usePackagesFindByIdClasses(
      {
        id: toggleEvents.data?.id,
        filter: {
          locationId: eventsLocationId,
        },
      },
      {
        enabled: !!eventsLocationId && !!toggleEvents.data?.id,
      }
    );

  const {
    isLoading: isUpdatingPackageEvents,
    mutate: putPackageEventsRequest,
  } = usePackagesUpdateClasses({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { data: packageProgrammes, isFetching: isFetchingPackageProgrammes } =
    usePackagesFindByIdProgrammes(
      {
        id: toggleProgrammes.data?.id,
      },
      {
        enabled: !!toggleProgrammes.data?.id,
      }
    );

  const {
    isLoading: isUpdatingPackageProgrammes,
    mutate: putPackageProgrammesRequest,
  } = usePackagesUpdateProgrammes({
    onSuccess: resetToggleProgrammes,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { data: packageLocations, isFetching: isFetchingPackageLocations } =
    usePackagesFindByIdLocations(
      {
        id: toggleLocations.data?.id,
      },
      {
        enabled: !!toggleLocations.data?.id,
      }
    );

  const {
    isLoading: isUpdatingPackageLocations,
    mutate: putPackageLocationsRequest,
  } = usePackagesUpdateLocations({
    onSuccess: resetToggleLocations,
    meta: {
      useOnSuccessToast: true,
    },
  });

  const { data: packageTags, isFetching: isFetchingPackageTags } = useTagsFind(
    {
      filter: {
        type: tagsTypes?.PACKAGE,
        tenantId,
      },
      paging: {
        perPage: -1,
      },
    },
    {
      enabled: togglePackage.isVisible,
    }
  );

  const onPutPostRequest = (values) => {
    if (togglePackage.type === 'create') {
      postPackagesCreateRequest(values);
    } else {
      // debugger;
      putPackagesUpdateRequest({
        id: togglePackage.data.id,
        ...values,
        limit:
          Number(togglePackage.data?.limit) !== Number(values?.limit)
            ? Number(values.limit)
            : undefined,
      });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setTogglePackage({
          type: 'update',
          data: packagesFind?.data?.data?.find((item) => item.id === id),
        });
        break;

      case 'manageEvents':
        setToggleEvents({
          data: { id },
        });
        break;

      case 'manageProgrammes':
        setToggleProgrammes({
          data: { id },
        });
        break;

      case 'manageLocations':
        setToggleLocations({
          data: { id },
        });
        break;

      case 'activate':
      case 'deactivate':
        putPackagesUpdateRequest({
          id,
          isActive:
            packagesFind?.data?.data.find((item) => item.id === id).isActive ===
            1
              ? 0
              : 1,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {(packagesFind.isFetching || isLoadingPackagesUpdate) && (
        <ProcessingBar />
      )}

      {togglePackage.isVisible && (
        <Modal
          title={`${
            togglePackage.type === 'create' ? t('create') : t('update')
          } ${t('package')}`}
          onClose={resetTogglePackage}
        >
          <FormPackage
            initialValues={togglePackage.data}
            isCreate={togglePackage.type === 'create'}
            isFetchingPackageTags={isFetchingPackageTags}
            isLoading={isLoadingPackagesUpdate || isLoadingPackagesCreate}
            packageTags={packageTags}
            onSubmit={onPutPostRequest}
          />
        </Modal>
      )}

      {toggleEvents.isVisible && (
        <Modal
          isSidebar
          title={t('packageClasses')}
          onClose={() => {
            resetToggleEvents();
            setEventsLocationId(undefined);
          }}
        >
          <FormEventsQuery
            locationOptions={locationOptions}
            onSubmit={({ locationId }) => setEventsLocationId(locationId)}
          />

          <Box height={4} />

          {eventsLocationId && (
            <FormEvents
              isFetchingPackageEvents={isFetchingPackageEvents}
              isLoading={isUpdatingPackageEvents}
              packageEvents={packageEvents || []}
              onSubmit={(values) =>
                putPackageEventsRequest({
                  id: toggleEvents.data.id,
                  locationId: eventsLocationId,
                  ...values,
                })
              }
            />
          )}
        </Modal>
      )}

      {toggleProgrammes.isVisible && (
        <Modal
          isSidebar
          title={t('packageProgrammes')}
          onClose={resetToggleProgrammes}
        >
          <FormProgrammes
            isFetchingPackageProgrammes={isFetchingPackageProgrammes}
            isLoading={isUpdatingPackageProgrammes}
            packageProgrammes={packageProgrammes || []}
            onSubmit={(values) =>
              putPackageProgrammesRequest({
                id: toggleProgrammes.data.id,
                ...values,
              })
            }
          />
        </Modal>
      )}

      {toggleLocations.isVisible && (
        <Modal
          isSidebar
          title={t('packageLocations')}
          onClose={resetToggleLocations}
        >
          <FormLocations
            isFetchingPackageLocations={isFetchingPackageLocations}
            isLoading={isUpdatingPackageLocations}
            packageLocations={packageLocations?.data || []}
            onSubmit={(values) =>
              putPackageLocationsRequest({
                id: toggleLocations.data.id,
                ...values,
              })
            }
          />
        </Modal>
      )}

      <Setting
        boxTitleContainerRenderRight={() => (
          <Box ml='auto'>
            <Chip
              hasAlternateBackground
              icon='add'
              title={t('createNew')}
              onClick={() => setTogglePackage({ type: 'create' })}
            />
          </Box>
        )}
        title={t('packageManagement')}
      >
        <FormPackageQuery initialValues={query} onSubmit={setQuery} />

        <Divider pb={4} />

        <Table
          data={packagesFind?.data?.data}
          isLoading={packagesFind.isFetching}
          paging={packagesFind.data?.paging}
          publicToken={publicToken}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
