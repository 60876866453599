import { Formik } from 'formik';
import { Box, Button, Col, Field, Row } from 'octiv-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default ({
  isLoading,
  initialValues = {},
  locationOptions,
  loggedInUserTypeId,
  ...props
}) => {
  const { t } = useTranslation();

  let initialLocationId;

  if (initialValues.typeId === 6 || initialValues.typeId === 8) {
    initialLocationId = initialValues.locations?.[0]?.id;
  }

  return (
    <Formik
      {...props}
      enableReinitialize
      initialValues={{
        typeId: initialValues.typeId,
        locationId: initialLocationId,
        bio: initialValues.bio || undefined,
      }}
      validationSchema={Yup.object().shape({
        typeId: Yup.string().required(t('required')),
        locationId: Yup.string().when('typeId', {
          is: (value) => Number(value) === 6 || Number(value) === 8,
          then: Yup.string().required(t('required')),
        }),
        bio: Yup.string(),
      })}
    >
      {({ handleSubmit, dirty, values: { typeId } }) => {
        const canUpdate =
          typeId === 2
            ? loggedInUserTypeId === 1 || loggedInUserTypeId === 2
            : loggedInUserTypeId !== 3;

        return (
          <form onSubmit={handleSubmit}>
            <Row>
              {(loggedInUserTypeId === 1 ||
                loggedInUserTypeId === 2 ||
                (loggedInUserTypeId === 5 && typeId !== 2)) && (
                <Col md={6} xl={4}>
                  <Field
                    isSelect
                    label={t('accountType')}
                    name='typeId'
                    options={[
                      ...(loggedInUserTypeId === 1 || loggedInUserTypeId === 2
                        ? [
                            {
                              label: t('owner'),
                              value: 2,
                            },
                          ]
                        : []),
                      ...(loggedInUserTypeId === 1 ||
                      loggedInUserTypeId === 2 ||
                      loggedInUserTypeId === 5
                        ? [
                            {
                              label: t('admin'),
                              value: 5,
                            },
                          ]
                        : []),
                      {
                        label: t('trainer'),
                        value: 3,
                      },
                      {
                        label: t('locationAdmin'),
                        value: 6,
                      },
                      {
                        label: t('locationCheckIn'),
                        value: 8,
                      },
                    ]}
                  />
                </Col>
              )}

              {(typeId === 6 || typeId === 8) && (
                <Col md={6} xl={4}>
                  <Field
                    isSelect
                    label={t('location')}
                    name='locationId'
                    options={locationOptions}
                  />
                </Col>
              )}
            </Row>

            <Box height={4} />

            <Row>
              <Col>
                <Field
                  as='textarea'
                  isDisabled={!canUpdate}
                  label={t('bio')}
                  name='bio'
                />
              </Col>

              <Col>
                <Button
                  isDisabled={!dirty}
                  isLoading={isLoading}
                  text={t('submit')}
                  type='submit'
                />
              </Col>
            </Row>
          </form>
        );
      }}
    </Formik>
  );
};
