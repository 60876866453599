import { Box, Chip, Modal, ProcessingBar } from 'octiv-components';
import { useActiveUserTenant } from 'octiv-context/ActiveUserTenant';
import { usePaging, useToggle } from 'octiv-hooks';
import {
  usePackagesCreate,
  usePackagesFind,
  usePackagesFindByIdClasses,
  usePackagesUpdate,
  usePackagesUpdateClasses,
} from 'octiv-hooks/requests/Packages';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Setting from '../../components/Setting';
import FormEvents from './FormEvents';
import FormEventsQuery from './FormEventsQuery';
import FormPackage from './FormPackage';
import Table from './Table';

export default () => {
  const { t } = useTranslation();
  const dropInPackageTypeId = 4;

  const {
    tenant: { locationOptions },
    selectedLocation,
  } = useActiveUserTenant();

  const [paging, setPaging] = usePaging();
  const [togglePackage, setTogglePackage, resetTogglePackage] = useToggle();
  const [toggleEvents, setToggleEvents, resetToggleEvents] = useToggle();
  const [eventsLocationId, setEventsLocationId] = useState(undefined);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    refetch: getSettingsRequest,
  } = usePackagesFind({
    filter: {
      typeId: dropInPackageTypeId,
      isActive: 1,
    },
    paging,
  });

  const { isLoading: isCreatingSetting, mutate: postSettingRequest } =
    usePackagesCreate({
      onSuccess: () => {
        getSettingsRequest();
        resetTogglePackage();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isUpdatingSetting, mutate: putSettingRequest } =
    usePackagesUpdate({
      onSuccess: () => {
        getSettingsRequest();
        resetTogglePackage();
      },
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { isLoading: isDeletingSetting, mutate: deleteSettingRequest } =
    usePackagesUpdate({
      onSuccess: getSettingsRequest,
      meta: {
        useOnSuccessToast: true,
      },
    });

  const { data: packageEvents, isFetching: isFetchingPackageEvents } =
    usePackagesFindByIdClasses(
      {
        id: toggleEvents.data?.id,
        filter: {
          locationId: eventsLocationId,
        },
      },
      {
        enabled: !!eventsLocationId && !!toggleEvents.data?.id,
      }
    );

  const {
    isLoading: isUpdatingPackageEvents,
    mutate: putPackageEventsRequest,
  } = usePackagesUpdateClasses({
    meta: {
      useOnSuccessToast: true,
    },
  });

  const onPutPostRequest = (values) => {
    if (togglePackage.type === 'create') {
      postSettingRequest({
        ...values,
        // NOTE: DropInPackges controller mostly fell away, in favour of the Packages controller.
        // Therefore some defaults (not present on the form) needed to be added to make this work
        typeId: dropInPackageTypeId,
        isHidden: 0,
        isDisplayed: 1,
        isDisplayOnBuyPackages: 1,
      });
    } else {
      putSettingRequest({
        id: togglePackage.data.id,
        isHidden: 0,
        isDisplayed: 1,
        isDisplayOnBuyPackages: 1,
        ...values,
      });
    }
  };

  const onClickAction = ({ action, id }) => {
    switch (action) {
      case 'edit':
        setTogglePackage({
          type: 'update',
          data: settings?.data?.find((item) => item.id === id),
        });
        break;

      case 'manageEvents':
        setToggleEvents({
          data: { id },
        });
        break;

      case 'delete':
        deleteSettingRequest({
          id,
          isActive: 0,
          // TODO: since this is a DELETE, it should probably just be set to isActive: 0
          // isActive: settings?.data?.find((item) => item.id === id).isActive
          //   ? 0
          //   : 1,
        });
        break;

      default:
        break;
    }
  };

  return (
    <>
      {isDeletingSetting && <ProcessingBar />}

      {togglePackage.isVisible && (
        <Modal
          isSidebar
          title={`${
            togglePackage.type === 'create' ? t('create') : t('update')
          } ${t('package')}`}
          onClose={resetTogglePackage}
        >
          <FormPackage
            initialValues={togglePackage?.data || {}}
            isHealthProvider={selectedLocation?.healthProviders?.length > 0}
            isLoading={isUpdatingSetting || isCreatingSetting}
            onSubmit={onPutPostRequest}
          />
        </Modal>
      )}

      {toggleEvents.isVisible && (
        <Modal
          isSidebar
          title={t('packageClasses')}
          onClose={() => {
            resetToggleEvents();
            setEventsLocationId(undefined);
          }}
        >
          <FormEventsQuery
            locationOptions={locationOptions}
            onSubmit={({ locationId }) => {
              setEventsLocationId(locationId);
            }}
          />

          <Box height={4} />

          {eventsLocationId && (
            <FormEvents
              isFetchingPackageEvents={isFetchingPackageEvents}
              isLoading={isUpdatingPackageEvents}
              packageEvents={packageEvents || []}
              onSubmit={(values) =>
                putPackageEventsRequest({
                  id: toggleEvents.data.id,
                  locationId: eventsLocationId,
                  ...values,
                })
              }
            />
          )}
        </Modal>
      )}

      <Setting
        boxTitleContainerRenderRight={() => (
          <Box ml='auto'>
            <Chip
              hasAlternateBackground
              icon='add'
              title={t('createNew')}
              onClick={() => setTogglePackage({ type: 'create' })}
            />
          </Box>
        )}
        title={t('packages')}
      >
        <Table
          hasAlternateBackground
          data={settings?.data || []}
          isLoading={isFetchingSettings}
          paging={settings?.paging}
          setPaging={setPaging}
          onClickAction={onClickAction}
        />
      </Setting>
    </>
  );
};
